//      
import React, { memo, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import useMouseMove from './hooks/use_mouse_move';

const useStyles = makeStyles(() => ({
  container: props => ({
    height: props.height,
    position: 'relative',
    paddingBottom: 4,
  }),
  dragger: {
    top: 4,
    left: 'calc(50% - 25px)',
    width: 25,
    cursor: 'row-resize',
    height: 4,
    position: 'relative',
    background: 'black',
  },
}));

              
                 
             
                          
                      
                      
    
                     
  

function ResizableBox({
  children,
  options = {
    defaultHeight: 300,
    maxHeight: 800,
    minHeight: 200,
  },
  className,
}       ) {
  let ref = useRef(null);
  let [h, setH] = useState(options?.defaultHeight);
  const classes = useStyles({
    height: h,
  });
  let [onDown] = useMouseMove(e => {
    if (ref.current) {
      let newH = e.clientY - ref.current.offsetTop;
      if (newH > options?.minHeight && newH < options?.maxHeight) {
        setH(newH);
      }
    }
  });

  return (
    <div className={classes.container} ref={ref}>
      {children}
      <div className={className || classes.dragger} draggable={false} onMouseDown={onDown} />
    </div>
  );
}

export default memo       (ResizableBox);
