//      
import React, { memo } from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

let useStyles = makeStyles({
  cardContainer: {
    backgroundColor: palette.gray50,
    minHeight: 220,
    height: '100%',
  },
});

              
                 
  

function ToolkitCard({ children }       ) {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid container>{children}</Grid>
      </CardContent>
    </Card>
  );
}

export default memo       (ToolkitCard);
