//     
import isAfter from 'date-fns/is_after';
import parse from 'date-fns/parse';
import isToday from 'date-fns/is_today';
import isThisYear from 'date-fns/is_this_year';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import format from 'date-fns/format';
import addHours from 'date-fns/add_hours';
import startOfDay from 'date-fns/start_of_day';
import formatDate from 'date-fns/format';
import getDay from 'date-fns/get_day';
import getHour from 'date-fns/get_hours';
import addDays from 'date-fns/add_days';
import capitalize from 'lodash/capitalize';
import { WeekdaysEnum } from '@dt/horizon-api';
                                                
import dateFormat from 'date-fns/format';
/**
 * So date handling in sevenhell is problematic… this has probably led to a number of incorrect outputs in the portal. An ISO8601 string with no timezone is considered local, so it is a mistake by Endpoints to not provide TZ. It’s made worse, however, by inconsistencies in the API. Some return the timezone, some do not. I can’t pass this string to a date library because I can’t tweak its assumptions about ISO 8601. So I have to parse the string, see if it has a timezone, and append `Z` if it does not. I *should* be using a proper ISO8601 parser for this, but since that could be expensive in bytes, I’m going to use a regex, which I hate. :goberserk:
 *
 * NOTE: The 21st capture group is the one we care about here. It contains nothing if there's no timezone in the string, or a Z, or the +/-dd:dd offset.
 * @type {RegExp}
 */
const ISO8601Regexp = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

/* Given Date in string, returns string in 'MMM DD, YYYY' format */
export const isoStringToDate = (d               , shouldDisplayHours          )         => {
  const baseFormat = 'MMM DD, YYYY';
  return dateFormat(d, `${baseFormat}${shouldDisplayHours ? ' @ HH:mm' : ''}`);
};

export const isoStringToDateWithTime = (d               )         => dateFormat(d, 'MMM DD, YYYY @ H:mm');

export function sortObjectsByDateProperty            (propertyName         = 'date', a    , b    ) {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  if (isAfter(a[propertyName], b[propertyName])) {
    return -1;
  } else {
    return 1;
  }
}

/**
 * See above for an explanation about why we do this.
 * @param iso8601string
 * @return {string}
 * @constructor
 */
export function ISO8601UTCStringCast(iso8601string        )         {
  const match = ISO8601Regexp.exec(iso8601string);

  // If we can't figure out what the string is, or if it already has a timezone... just return it
  if (!match || match[21]) {
    return iso8601string;
  }

  // Otherwise, append a Z which indicated UTC
  return iso8601string + 'Z';
}

                      
                  
                 
     
  

export function formattedFromNow(formats                = {}) {
  const { short, long } = formats;
  return function(date               )         {
    const dateParsed = date instanceof Date ? date : parse(ISO8601UTCStringCast(date));
    if (isToday(dateParsed)) {
      return distanceInWordsToNow(dateParsed, {
        includeSeconds: true,
        addSuffix: true,
      });
    } else if (isThisYear(dateParsed)) {
      return format(dateParsed, short || 'MMM D');
    } else {
      return format(dateParsed, long || 'YYYY-MM-DD');
    }
  };
}

export const fromNow = formattedFromNow();

export const fromNowTime = formattedFromNow({
  long: 'YYYY-MM-DD h:mm a',
  short: 'MMM D h:mm a',
});

// 02:34:56+00:00

const isoTimeRegEx = /(\d{2}:)\d{2}:\d{2}[-+]\d{2}:\d{2}/;

/**
 * This turns ISO time to a rounded UTC by changing
 * minuets, seconds and the UTC offset to zero. For
 * example:
 *    `12:32:42+07:00` => `12:00:00+00:00`
 *
 * This is needed tp populate the Time dropdown input where
 * we are only interested in exact hour and minutes and seconds
 * are insignificant.
 *
 * Ref:
 *   - https://datatheorem.slack.com/archives/C107CJ11V/p1600451427002700
 *   - packages/material-components/PolicyRuleSQLInjectionScheduledTimeInput.js
 */
export function roundHour(time        )         {
  let valid = isoTimeRegEx.test(time);
  return valid ? time.replace(isoTimeRegEx, '$100:00+00:00') : time;
}

export const hoursOfDay                          = [
  ['00:00', ':00+00:00'],
  ['01:00', ':00+00:00'],
  ['02:00', ':00+00:00'],
  ['03:00', ':00+00:00'],
  ['04:00', ':00+00:00'],
  ['05:00', ':00+00:00'],
  ['06:00', ':00+00:00'],
  ['07:00', ':00+00:00'],
  ['08:00', ':00+00:00'],
  ['09:00', ':00+00:00'],
  ['10:00', ':00+00:00'],
  ['11:00', ':00+00:00'],
  ['12:00', ':00+00:00'],
  ['13:00', ':00+00:00'],
  ['14:00', ':00+00:00'],
  ['15:00', ':00+00:00'],
  ['16:00', ':00+00:00'],
  ['17:00', ':00+00:00'],
  ['18:00', ':00+00:00'],
  ['19:00', ':00+00:00'],
  ['20:00', ':00+00:00'],
  ['21:00', ':00+00:00'],
  ['22:00', ':00+00:00'],
  ['23:00', ':00+00:00'],
];

export const daysOfWeek                  = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export function UTCToLocalTime(time        ) {
  const timeUTC = Number(time.split(':')[0]) - new Date().getTimezoneOffset() / 60;
  return `${Math.abs(timeUTC < 0 ? timeUTC + 24 : timeUTC)}:00 ${timeUTC < 0 ? '(Day before)' : ''}`;
}

// This function converts Military(24 hour) to Standard(12 hours am/pm )
export function convertMilitaryHourToStandardHour(militaryFormatHour         ) {
  return formatDate(addHours(startOfDay(new Date()), Number(militaryFormatHour?.split(':')[0] || '0')), 'HH a');
}

// This function return sorted list of weekdays by Mon first, Sun last.
export function sortedWeekdaysToString(selectedWeekdays                  ) {
  return Object.keys(WeekdaysEnum)
    .filter((weekday          ) => selectedWeekdays?.includes(weekday))
    .map((item          ) => capitalize(item))
    .join(', ');
}

export function findClosestDateInListOfWeekDays(WeekdaysArray                  , dateTime         ) {
  const utcDateTime = new Date()
    .toUTCString()
    .toString()
    .replace(' GMT', '');
  const selectedHour = Number(dateTime?.split(':')[0] || '0');
  const currentDayOfWeek = getDay(new Date(utcDateTime));
  const today = daysOfWeek[currentDayOfWeek];
  const currentHour = getHour(new Date(utcDateTime));

  // find the next closest day from WeekdaysArray with this order => ( Mon => 1, Tue => 2, etc )
  const daysUntilNextClosestDay = [
    ...daysOfWeek.filter((_, idx) => idx >= currentDayOfWeek),
    ...daysOfWeek.filter((_, idx) => idx < currentDayOfWeek),
  ].findIndex(
    weekday =>
      WeekdaysArray?.includes(weekday) && ((today === weekday && currentHour < selectedHour) || today !== weekday),
  );

  //create closest Date by adding Number of Days left to closest day to today's UTC Date
  return startOfDay(addDays(new Date(utcDateTime), daysUntilNextClosestDay > 0 ? daysUntilNextClosestDay : 0));
}
