//      
import React, { memo } from 'react';
import { ExtLink } from '../index';
import { Tooltip } from '@mui/material';
import { palette } from '@dt/theme';
import { makeStyles } from '@mui/styles';
import CicdIcon from '@mui/icons-material/TrackChanges';

let useStyle = makeStyles({
  menuOuter: {
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      backgroundColor: palette.brand20,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

                

function ConfigurableLHSMenuDevSecOps() {
  const styles = useStyle();

  return (
    <ExtLink to="/devsecops" className={styles.menuOuter} target="_self">
      <Tooltip disableInteractive title="DevSecOps">
        <div className={styles.menuInner}>
          <div className={styles.menuIconBox}>
            <CicdIcon color="inherit" />
          </div>
          <div className={styles.menuText}>DevSecOps</div>
        </div>
      </Tooltip>
    </ExtLink>
  );
}

export default memo       (ConfigurableLHSMenuDevSecOps);
