//      
import { useState, useEffect } from 'react';
                                                                          

                                                                     

export default function useAssetTagEditValidation() {
  const [assetTags, setAssetTags] = useState                 ([]);
  const [validations, setValidations] = useState([]);

  useEffect(() => {
    let findEmptyKeys = assetTags
      .filter(item => !item.tag.length && item.value?.length)
      .map(item => ({
        tag: item.tag,
        value: item.value,
        message: `Asset Key can't be empty`,
      }));

    let findDuplicateKeys = assetTags
      .filter(({ tag }, index) =>
        assetTags.find((item, idx) => item.tag === tag && index !== idx),
      )
      .map(item => ({
        tag: item.tag,
        value: item.value,
        message: `Asset Key can't be duplicated`,
      }));

    setValidations([...findEmptyKeys, ...findDuplicateKeys]);
  }, [assetTags]);

  return {
    set: setAssetTags,
    validations,
  };
}
