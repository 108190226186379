//      
import React, {                         memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

let useStyle = makeStyles({
  drawer: {
    // Just enough to go above the `StickyTable` but still below MUI `Tooltip` (͠≖ ͜ʖ͠≖)
    // Refer to https://material-ui.com/customization/z-index for list of z-indexes
    // used by Material UI components
    zIndex: 1201,
    height: '100%',
    width: '72px',
    minWidth: '72px',
    overflowX: 'hidden',
    backgroundColor: palette.brand30,
    willChange: 'width',
    transition: 'width 160ms cubic-bezier(1, 0, 0, 1)',

    '&.open': {
      width: '256px',
      minWidth: '256px',
    },
  },
});

              
                
                      
  

function Drawer({ open, children }       ) {
  let css = useStyle();
  return <div className={`${css.drawer} ${open ? 'open' : ''}`}>{children}</div>;
}

export default memo       (Drawer);
