//      
export let AssetTypeDict = {
  RESTFUL_API: ('RESTFUL_API'               ),
  SINGLE_PAGE_WEB_APP: ('SINGLE_PAGE_WEB_APP'                       ),
  API_DOMAIN: ('API_DOMAIN'              ),
  CLOUD_RESOURCE: ('CLOUD_RESOURCE'                  ),
  GRAPHQL_API: ('GRAPHQL_API'               ),
  GRPC_SERVICE: ('GRPC_SERVICE'                ),
  GRPC_METHOD: ('GRPC_METHOD'               ),
  KUBERNETES_CLUSTER: ('KUBERNETES_CLUSTER'                      ),
  KUBERNETES_CLUSTER_COMPONENT: ('KUBERNETES_CLUSTER_COMPONENT'                                ),
  SOAP_API: ('SOAP_API'            ),
  SOAP_API_OPERATION: ('SOAP_API_OPERATION'                      ),
  REPOSITORY: ('REPOSITORY'              ),
};
