//      
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { palette } from '@dt/theme';

let useStyles = makeStyles({
  summary: {
    paddingLeft: '8px',
    flexDirection: 'row-reverse',
  },
  panel_content: {
    overflow: 'hidden',
    flex: '1 1 auto',
  },
  icon: {
    marginRight: '4px',
    marginLeft: '4px',
    padding: '0px',
  },
  panel: {
    margin: '0 !important',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&.expanded': {
      margin: 'auto',
    },
  },
  panel_details: {
    backgroundColor: props => props.detailsBgColor || palette.gray50,
  },
});

              
                
                          
                  
  

/**
 * The only purpose of this component is having an
 * `ExpandablePanel` with the arrow button on the left.
 *
 * The content of the PanelSummary is passed as `summary`
 * and the content will be the `children`.
 */
function LeftExpandablePanel(props       ) {
  let css = useStyles(props);
  return (
    <Accordion classes={{ root: css.panel }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-header"
        id="panel-header"
        classes={{
          root: css.summary,
          expandIcon: css.icon,
          content: css.panel_content,
        }}
      >
        {props.summary}
      </AccordionSummary>
      <AccordionDetails classes={{ root: css.panel_details }}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export default memo       (LeftExpandablePanel);
