//     
import React, { memo } from 'react';
// $FlowFixMe[untyped-import] clsx does not have flow types
import clsx from 'clsx';


const variantToElement                                    = {
  titleXL: 'h1',
  titleL: 'h2',
  titleM: 'h3',
  titleS: 'h4',
  titleXS: 'h5',
  body: 'p',
  bodyS: 'p',
  caption: 'span',
  label: 'span',
  labelL: 'span',
  link: 'span',
  button: 'span',
  helperText: 'span',
  chip: 'span',
  numberXL: 'span',
  code: 'code',
};

                                                                                                           

              
             
            
            
            
             
          
           
             
           
            
          
            
                
          
              
           

              
                  
                    
                            
                 
                       
                 
                     
                   
                       
  

function Text({
  variant = 'body',
  component,
  style = {},
  color,
  className = '',
  children,
  label,
  noWrap,
  onClick,
}       ) {
  let Comp = component || variantToElement[variant];

  return (
    <Comp
      aria-label={label}
      className={clsx(`text-${variant}`, noWrap && 'text-truncate', className)}
      style={{ color, ...style }}
      onClick={onClick}
    >
      {children}
    </Comp>
  );
}

export default memo       (Text);
