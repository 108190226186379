//      

import { sharedLinksReceived, sharedLinksDeleteSuccess } from './actions';
                                                   
import immer from 'immer';

                                
                                                            
  

const initialState = {
  id: {},
};

                                                                                                    

export default immer                           ((draft, action         ) => {
  if (action.type === sharedLinksReceived.toString()) {
    for (let shared_link of action.payload) {
      draft.id[shared_link.id] = shared_link;
    }
  }

  if (action.type === sharedLinksDeleteSuccess.toString()) {
    draft.id[action.payload] = void 0;
  }
}, initialState);
