//      
import React, { useState, memo, useCallback, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { PiiTypeEnum, PiiTypeEnumHumanReadable,              } from '@dt/horizon-api';

let piiLabels = Object.keys(PiiTypeEnum).sort((a, b) => a.localeCompare(b));

let useStyles = makeStyles({
  form_control_root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  form_control_label: {
    width: '360px',
    marginLeft: '0px',
    marginRight: '0px',
    padding: '2px',
  },
  checkbox_root: {
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
});

              
                                   
                                     
  

              
                       
      
  

function PiiSelector({ defaultSelected = [], onChange }) {
  let css = useStyles();
  let selectedMap = Object.fromEntries(defaultSelected.map(d => [d, true]));
  let [selected, setSelected] = useState       (selectedMap);

  useEffect(() => {
    if (onChange) {
      let selectedKeys = Object.keys(selected).filter(k => Boolean(selected[k]));
      onChange(selectedKeys);
    }
  }, [selected, onChange]);

  let handleChange = useCallback(value => {
    setSelected(s => ({ ...s, [value]: !s[value] }));
  }, []);

  return (
    <FormControl component="fieldset" classes={{ root: css.form_control_root }}>
      {piiLabels.map(p => (
        <FormControlLabel
          key={p}
          classes={{ root: css.form_control_label }}
          control={
            <Checkbox
              disableRipple
              classes={{ root: css.checkbox_root }}
              size="small"
              color="primary"
              onChange={e => handleChange(e.target.value)}
              checked={Boolean(selected[p])}
              value={p}
            />
          }
          label={PiiTypeEnumHumanReadable[p]}
        />
      ))}
    </FormControl>
  );
}

export default memo       (PiiSelector);
