//     
import WarningIcon from '@mui/icons-material/Warning';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { ToolkitIcon } from '@dt/material-components';
import ProtectIcon from '@mui/icons-material/Security';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import BrandIconSupplierTrust from '@dt/brand/product-icon-supplier-trust';
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
import { capitalize } from '@dt/functions';
                                                             
                                               
                                                                                                        

const redirectExceptions = ['/api/shadow-assets'];

/*
    When in /mobile-secure
    + For entries
      - If we're in mobile-secure and the destination doesn't contain '/mobile-secure'/ AND doesn't already have redirect: true
        - redirect: true
        - target: _self
        - to: to: needs to be rewritten to /mobile/${to}

    + For  subentries:
      - If a link has redirect: true it means that we want to open in a new tab
        - redirect: true
        - target: "_blank"
        - to: existing to

      - If a link has redirect: false (or no redirect field) then all links except the ones with /mobile-secure need to have:
       - redirect: true
       - target: "_self"
       - to: needs to be rewritten to /mobile/${to}

    When in /mobile
      - Nothing to do, the object already has the proper urls
    */
const getEntriesWithRedirect = ({ isMobileSecure, entries }) =>
  entries.map(entry => {
    let target = entry.target || '_self';
    let to = entry.to;
    let redirect = entry.redirect || false;
    if (isMobileSecure && entry.to) {
      const hasRedirect = 'redirect' in entry;
      const isMobileSecureUrl = entry.to.match(/\/mobile-secure\//g);
      const needsUpdate = !isMobileSecureUrl && !hasRedirect;
      if (needsUpdate) {
        to = `/mobile${entry.to}`;
        redirect = true;
        target = '_self';
      }
    }

    const updatedSubEntries = entry.subEntries.map(sub => {
      if (isMobileSecure) {
        const genericLink = sub.genericLink || false;
        let target = sub.target || '_self';
        let to = sub.to;
        let redirect = sub.redirect || false;

        if (redirect && !genericLink) {
          target = '_blank';
          return {
            ...sub,
            to,
            target,
            redirect,
          };
        }

        const isMobileSecureUrl = sub.to.match(/\/mobile-secure\//g);
        const updatedTo = `${!isMobileSecureUrl && !genericLink ? '/mobile' : ''}${sub.to}`;

        return {
          ...sub,
          redirect: isMobileSecure && isMobileSecureUrl ? false : true,
          target: '_self',
          to: !redirectExceptions.includes(sub.to) ? updatedTo : sub.to,
        };
      }
      return sub;
    });

    return {
      ...entry,
      to,
      redirect,
      target,
      subEntries: updatedSubEntries,
    };
  });

export const MenuDashboard = ({
  product: {
    to: '/dashboard',
    name: 'Data Theorem',
    icon: DTIcon,
  },
  entries: [],
}                       );

export const MenuMobile = ({ isMobileSecure = false }                             )                        => {
  const product = {
    to: '/issues/priority',
    name: 'Mobile Secure',
    icon: MobileSecureIcon,
    ...(isMobileSecure && {
      redirect: true,
      target: '_self',
      to: `/mobile/issues/priority`,
    }),
  };

  const entries = [
    {
      icon: WarningIcon,
      name: 'Issues',
      to: '/issues/search',
      subEntries: [],
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/policies',
      redirect: !isMobileSecure,
      exact: false,
      target: '_self',
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/mobile-secure/v2/inventory/apps',
          redirect: !isMobileSecure,
          target: '_self',
          name: 'Inventory',
        },
        {
          to: '/api/shadow-assets',
          name: 'Shadow Assets',
        },
        {
          to: '/cloud/asm-setup',
          name: 'ASM Setup',
          redirect: true,
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      to: null, // No link
      subEntries: [
        {
          to: '/issues/security',
          name: 'Remote P1 Exploits',
        },
        {
          to: '/gatekeeper-gambling/blockers',
          name: 'Gatekeeper Gambling',
        },
        {
          to: '/kttk/secrets',
          name: 'Keys to the Kingdom',
        },
        {
          to: '/mobile-secure/mobile-phishing',
          name: 'Mobile Phishing',
          redirect: !isMobileSecure,
          target: '_self',
        },
        {
          to: '/ios/privacy',
          name: 'App Store Answers',
        },
        {
          to: '/android/privacy',
          name: 'Play Store Answers',
        },
        {
          to: '/csc/scans',
          name: 'Coffee Shop Compromise',
        },
        {
          name: 'Hack Me Amadeus',
          to: '/mobile-secure/custom-checks',
          redirect: !isMobileSecure,
          exact: false,
          target: '_self',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Active Protection',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/protect',
          name: 'Mobile Protect',
        },
        {
          to: '/sdk-issues/all',
          name: 'SCA',
        },
      ],
    },
  ];

  const entriesWithRedirect = getEntriesWithRedirect({
    isMobileSecure,
    entries,
  });

  return {
    useMobileRouting: !isMobileSecure,
    product,
    entries: entriesWithRedirect,
  };
};

export const MenuWeb = ({
  product: {
    to: '/web',
    name: 'Web Secure',
    icon: WebSecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      to: '/web/policy-violations',
      subEntries: [],
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/policies',
      exact: false,
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/web/inventory',
          name: 'Inventory',
        },
        {
          // Not on /web path
          to: '/api/shadow-assets',
          name: 'Shadow Assets',
        },
        {
          to: '/web/asm-setup',
          name: 'ASM Setup',
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      to: '/web/security-toolkits',
      subEntries: [
        {
          to: '/web/security-toolkits/sql-injection',
          name: 'SQL Injection',
        },
        {
          to: '/web/toxic-tokens',
          name: 'Toxic Tokens',
        },
        {
          to: '/web/security-toolkits/xss-attack/overview',
          name: 'XSS Attack',
        },
        {
          to: '/web/custom-checks',
          name: 'Hack Me Amadeus',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Active Protection',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/web/security-toolkits/xss-protection',
          name: 'XSS Protect',
        },
        {
          to: '/web/security-toolkits/web-protect',
          name: 'Web Protect',
        },
      ],
    },
  ],
}                       );

export const MenuManagement                        = {
  product: {
    to: '/dashboard/v2/products',
    name: 'Your Products',
    icon: DTIcon,
  },
  entries: [],
};

export const MenuDevSecOps = ({
  product: {
    to: '/devsecops',
    name: 'DevSecOps',
    icon: DTIcon,
  },
  entries: [],
}                       );

export const MenuApi = ({
  product: {
    to: '/api/overview',
    name: 'API Secure',
    icon: APISecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      to: '/api/policy-violations',
      subEntries: [],
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/policies',
      exact: false,
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/api/discover/inventory',
          name: 'Inventory',
        },
        {
          to: '/api/shadow-assets',
          name: 'Shadow Assets',
        },
        {
          to: '/api/asm-setup',
          name: 'ASM Setup',
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      to: null, // No link
      subEntries: [
        {
          to: '/api/inspect/hack-and-extract',
          name: 'Hack and Extract',
        },
        {
          to: '/api/inspect/detect-and-inject',
          name: 'Detect and Inject',
        },
        {
          to: '/api/ssrf-sploits-toolkit',
          name: 'SSRF Sploits',
        },
        {
          to: '/api/inspect/leaky-apis',
          name: 'Leaky APIs',
        },
        {
          to: '/api/custom-checks',
          name: 'Hack Me Amadeus',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Active Protection',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/api/protect',
          name: 'API Protect',
        },
      ],
    },
  ],
}                       );

export const MenuCloud = ({
  product: {
    to: '/cloud',
    name: 'Cloud Secure',
    icon: CloudSecureIcon,
  },
  entries: [
    {
      icon: WarningIcon,
      name: 'Policy Violations',
      to: '/cloud/policy-violations',
      subEntries: [],
    },

    {
      icon: GavelIcon,
      name: 'Policies',
      to: '/policies',
      exact: false,
      subEntries: [],
    },

    {
      icon: GroupWorkIcon,
      name: 'ASM',
      to: null, // No link
      exact: false,
      subEntries: [
        {
          to: '/cloud/inventory',
          name: 'Inventory',
        },
        {
          // Not on /cloud path
          to: '/api/shadow-assets',
          name: 'Shadow Assets',
        },
        {
          to: '/cloud/asm-setup',
          name: 'ASM Setup',
        },
      ],
    },

    {
      icon: ToolkitIcon,
      name: 'Hacker Toolkits',
      to: '/cloud/security-toolkits',
      subEntries: [
        {
          to: '/cloud/security-toolkits/headliner-hacks',
          name: 'Headliner Hacks',
        },
        {
          to: '/cloud/security-toolkits/cloud-leaks',
          name: 'Cloud Leaks',
        },
        {
          to: '/cloud/security-toolkits/code-red',
          name: 'Code Red',
        },
        {
          to: '/cloud/security-toolkits/ransomware',
          name: 'Ransomware',
        },
        {
          to: '/cloud/security-toolkits/cloud-gotchas',
          name: 'Cloud Gotchas',
        },
        {
          to: '/cloud/security-toolkits/xdr',
          name: 'Cloud XDR',
        },
        {
          to: '/cloud/security-toolkits/dlp',
          name: 'Cloud DLP',
        },
        {
          to: '/cloud/security-toolkits/major-malfunction',
          name: 'Major Malfunction',
        },
      ],
      exact: true,
    },

    {
      icon: ProtectIcon,
      name: 'Active Protection',
      to: null,
      exact: true,
      subEntries: [
        {
          to: '/cloud/policy-violations?filter_by_belongs_to_products=CLOUD_SECURE',
          name: 'CSPM',
        },
        {
          to: '/cloud/active-protection/ciem',
          name: 'CIEM',
        },
        {
          to: '/cloud/security-toolkits/correct-and-protect',
          name: 'Correct and Protect',
        },
        {
          to: '/cloud/iac-scan',
          name: 'IaC Scan',
        },
      ],
    },
  ],
}                       );

export const MenuScs = ({
  accountInfo,
  currentUser,
}   
                            
                     
 )                        => {
  let supplierTrustLink = `/supply-chain/supplier-trust`;

  if (!currentUser?.is_accessing_a_vendor && accountInfo?.vendor_id === accountInfo?.current_vendor?.id) {
    const id = accountInfo?.current_vendor?.id || '';
    supplierTrustLink = `/supply-chain/supplier-trust/suppliers/${id}/overview`;
  }

  return {
    product: {
      to: '/supply-chain',
      name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Secure',
      icon: BrandIconSupplyChainSecurity,
    },
    entries: accountInfo?.hide_non_vendor_supply_chain_security_sections
      ? [
          {
            icon: GavelIcon,
            name: 'Policies',
            to: '/policies',
            subEntries: [],
          },
          {
            icon: ToolkitIcon,
            name: 'Headliner Hacks',
            to: '/supply-chain/policies/headline-hacks',
            subEntries: [],
          },
          {
            icon: BrandIconSupplierTrust,
            name: capitalize(accountInfo?.vendor_supply_chain_security_whitelabeling_name) || 'Supplier Trust',
            to: null,
            subEntries: [
              {
                name: `${capitalize(accountInfo?.vendor_supply_chain_security_vendor_singular_term) ||
                  'Supplier'} Inventory`,
                to: supplierTrustLink,
              },
            ],
          },
        ]
      : [
          {
            icon: GavelIcon,
            name: 'Policies',
            to: '/policies',
            subEntries: [],
          },
          {
            icon: GroupWorkIcon,
            name: 'ASM Setup',
            to: '/supply-chain/asm-setup',
            subEntries: [],
          },
          {
            icon: ToolkitIcon,
            name: 'Headliner Hacks',
            to: '/supply-chain/policies/headline-hacks',
            subEntries: [],
          },
          {
            icon: StoreMallDirectoryIcon,
            name: 'Supply Chain Software',
            to: null,
            exact: true,
            subEntries: [
              {
                name: 'Supply Chain Orgs',
                to: '/supply-chain/vendors',
              },
              {
                name: 'My Apps',
                to: '/supply-chain/my-apps',
              },
              {
                name: 'SBOM Security Issues',
                to: '/supply-chain/security-issues',
              },
              {
                name: 'SBOM',
                to: '/supply-chain/sbom',
              },
            ],
          },
          {
            icon: BrandIconSupplierTrust,
            name: capitalize(accountInfo?.vendor_supply_chain_security_whitelabeling_name) || 'Supplier Trust',
            to: null,
            subEntries: [
              {
                name: `${capitalize(accountInfo?.vendor_supply_chain_security_vendor_singular_term) ||
                  'Supplier'} Inventory`,
                to: supplierTrustLink,
              },
            ],
          },
        ],
  };
};

export const MenuSupplyChainSecurity = ({
  accountInfo,
}   
                            
                     
 )                        => {
  return {
    product: {
      to: '/openscan/v2',
      name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Security',
      icon: BrandIconSupplyChainSecurity,
    },
    entries: [],
  };
};
